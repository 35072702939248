// Code generated by Slice Machine. DO NOT EDIT.

export const components = {
  demo_booking: () =>
    import(
      /* webpackChunkName: "prismic__slices__demo_booking" */ './DemoBooking/index.vue'
    ),
  downloads: () =>
    import(
      /* webpackChunkName: "prismic__slices__downloads" */ './Downloads/index.vue'
    ),
  feature_banner: () =>
    import(
      /* webpackChunkName: "prismic__slices__feature_banner" */ './FeatureBanner/index.vue'
    ),
  feature_banner_wide: () =>
    import(
      /* webpackChunkName: "prismic__slices__feature_banner_wide" */ './FeatureBannerWide/index.vue'
    ),
  feature_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__feature_list" */ './FeatureList/index.vue'
    ),
  feature_with_image: () =>
    import(
      /* webpackChunkName: "prismic__slices__feature_with_image" */ './FeatureWithImage/index.vue'
    ),
  headline: () =>
    import(
      /* webpackChunkName: "prismic__slices__headline" */ './Headline/index.vue'
    ),
  hero: () =>
    import(/* webpackChunkName: "prismic__slices__hero" */ './Hero/index.vue'),
  logo_cloud: () =>
    import(
      /* webpackChunkName: "prismic__slices__logo_cloud" */ './LogoCloud/index.vue'
    ),
  rich_text: () =>
    import(
      /* webpackChunkName: "prismic__slices__rich_text" */ './RichText/index.vue'
    ),
  simple_menu_element: () =>
    import(
      /* webpackChunkName: "prismic__slices__simple_menu_element" */ './SimpleMenuElement/index.vue'
    ),
  simple_menu_link: () =>
    import(
      /* webpackChunkName: "prismic__slices__simple_menu_link" */ './SimpleMenuLink/index.vue'
    ),
  testimonials: () =>
    import(
      /* webpackChunkName: "prismic__slices__testimonials" */ './Testimonials/index.vue'
    ),
}
