
import { components } from '~/slices'

export default {
  name: 'IndexPage',
  async asyncData({ app, $prismic, params, error }) {
    const document = await $prismic.api.getSingle('homepage', {
      lang: app.i18n.localeProperties.prismic,
    })

    if (document) {
      return { document: document.data }
    } else {
      error({ statusCode: 404, message: 'Page not found' })
    }
  },
  data() {
    return { components }
  },
  head() {
    const i18nHEAD = this.$nuxtI18nHead({ addSeoAttributes: true })

    i18nHEAD.link = i18nHEAD.link.map((link) => {
      if (!link.href.endsWith('/')) {
        link.href = link.href + '/'
      }
      return link
    })

    return {
      title: this.document.meta_titel,
      htmlAttrs: {
        ...i18nHEAD.htmlAttrs,
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.document.meta_description,
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.document.meta_titel,
        },
        {
          hid: 'og-desc',
          property: 'og:description',
          content: this.document.meta_description,
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content: this.document.meta_open_graph_image.url,
        },
        ...i18nHEAD.meta,
      ],
      link: [...i18nHEAD.link],
    }
  },
  computed: {
    heroVideoPoster() {
      return this.document.hero_video_poster
        ? this.document.hero_video_poster.url
        : ''
    },
  },
  methods: {
    playPause(event) {},
  },
}
